import { CheckIcon } from "@heroicons/react/20/solid";
import "animate.css/animate.min.css";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styled from "styled-components";
import { InstagramEmbed } from 'react-social-media-embed';
import axios from 'axios'

const MaskedImage = styled.div`
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0),
    linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-size: 200px ${(props) => (props.scroll < 0 ? 0 : props.scroll)}px;
  -webkit-mask-repeat: no-repeat;
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0),
    linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-size: 200px ${(props) => (props.scroll < 0 ? 0 : props.scroll)}px;
  mask-repeat: no-repeat;
  position: absolute;
`;

const tiers = [
  {
    name: "Primer encuentro",
    id: "tier-personal",
    features: [
      'Nos envías la ecografía, preferiblemente de la semana 25 en adelante.',
      'En 3 días te confirmamos si todo está OK para hacer tu pago.',
      'Te pedimos unos datos adicionales para personalizar tu imagen.',
    ],
    featured: true,
  },
];

const stats = [
  { id: 1, name: "Imágenes Creadas", value: "+100" },
  { id: 2, name: "Padres Felices", value: "+50" },
  { id: 3, name: "Satisfaciendo a Padres", value: "3 Países" },
];

const featuredTestimonial = {
  body: "Mi esposo llegó con la imágen de sorpresa y yo casi muero de amor, estamos felices de poder verle desde ya su carita.",
  author: {
    name: "Luisa y Carlos",
    imageUrl: "/carlos.jpeg",
  },
};

const testimonials = [
  [
    [
      {
        body: "Me emocionó mucho la carita de Sofi, estoy anhelando ya el día de su llegada para conocerla.",
        author: {
          name: "María y Jorge",
          imageUrl: "/maria.jpeg",
        },
      },
    ],
  ],
  [
    [
      {
        body: "Muy satisfecha con esta experiencia, muy buena atención y la imágen entregada con un diseño muy tierno y hermoso. Totalmente recomendado.",
        author: {
          name: "Ana",
          imageUrl: "/ana.jpeg",
        },
      },
    ],
    
  ],
];

const navigation = {
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/tubabyencamino",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/tubabyencamino",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
        </svg>
      ),
    },
    {
      name: "TikTok",
      href: "https://www.tiktok.com/@babyencaminoai",
      icon: (props) => (
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          {...props}
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}
          <path d="M9 12a4 4 0 1 0 4 4v-12a5 5 0 0 0 5 5" />{" "}
        </svg>
      ),
    },
    {
      name: "WhatsApp",
      href: "whatsapp://send?phone=+50686737870",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
        </svg>
      ),
    },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div
      className="w-full max-w-md lg:col-span-5 lg:pt-2"
      action="https://babyencamino.us12.list-manage.com/subscribe/post?u=d9463bc988445b65c94e02eac&amp;id=9b94c012c8&amp;f_id=001046e0f0"
      method="post"
    >
      <div className="flex gap-x-4">
        <input
          id="email-address"
          name="b_d9463bc988445b65c94e02eac_9b94c012c8"
          type="email"
          autoComplete="email"
          required
          className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
          placeholder="Ingresa tu correo"
          ref={(node) => (email = node)}
        />
        <button
          type="submit"
          className="flex-none rounded-md bg-pink-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
          onClick={submit}
        >
          Suscríbete
        </button>
      </div>
      {status === "success" && (
        <>
          <p className="font-semibold mt-4 text-sm leading-6 text-green-900">
            ¡Listo!, ya puedes revisar tu email para disfrutar tus beneficios.
          </p>
        </>
      )}
      <p className="mt-4 text-sm leading-6 text-gray-900">
        Suscríbete ya.
      </p>
    </div>
  );
};

export default function App() {
  const [scroll, setScroll] = useState(0);
  const [country, setCountry] = useState();
  const landscape = getCurrentDimension().width > 1000 ? true : false;
  const offset = landscape ? [215, 95, 295, 0, 200] : [435, 350, 545, 435, 630];

  useEffect(() => {
    console.log("load")
    axios.get('https://ipapi.co/json/').then((response) => {
      setCountry(response.data.country);
    })

    const debouncedHandleScroll = _.debounce(() => {
      if (window.pageYOffset > 1400) {
        return;
      }
      setScroll(window.pageYOffset);
    }, 10);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  const pricing = country == "CO" 
    ? "$45,000 pesos colombianos" 
    : country == "CR" 
    ? "₡6,000 colones" 
    : country == "ES"
    ? "€10 euros"
    : country == "CL"
    ? "₡10,000 pesos chilenos"
    : country == "MX"
    ? "$190 pesos"
    : country == "EC"
    ? "$260,000 pesos ecuatorianos"
    : country == "PE"
    ? "S/40 soles"
    : "$10 dólares"

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 pt-10 pb-10">
        <img className="h-40" src="/logo.jpeg" />
      </div>
      <main>
        <div className="relative isolate">
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#FA9391] to-[#FA9391] opacity-30"
              style={{
                clipPath:
                  "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
            />
          </div>
          <div>
            <div className="mx-auto max-w-7xl px-6 pb-32 sm:pt-60 lg:px-8 lg:pt-0">
              <div
                style={{ position: "relative", top: landscape ? -150 : 0 }}
                className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center"
              >
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Conoce a tu Bebé
                    <br />
                    antes de Nacer
                  </h1>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                    Experimenta lo increíble. Transformamos tus ecografías 3D en
                    imágenes realistas para que puedas hacerte una idea de cómo
                    se verá tu bebé recién nacido.
                    <br />
                    <br />
                    <p className="font-bold">
                      Te acercamos un paso más a la magia de la vida.
                    </p>
                  </p>
                </div>
                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <MaskedImage scroll={(scroll - offset[0]) * 1.5}>
                        <img
                          src="/0-4-zfaceswap.png"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </MaskedImage>
                      <div>
                        <img
                          src="/0-2-img2img.png"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </div>
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <MaskedImage scroll={(scroll - offset[1]) * 1.5}>
                        <img
                          src="/5-1-txt2img.png"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </MaskedImage>
                      <div>
                        <img
                          src="/5-1-img2img.png"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </div>
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <MaskedImage scroll={(scroll - offset[2]) * 1.5}>
                        <img
                          src="/7-0-txt2img.png"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </MaskedImage>
                      <div>
                        <img
                          src="/7-0-img2img.png"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </div>
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <MaskedImage scroll={(scroll - offset[3]) * 1.5}>
                        <img
                          src="/6-5-zfaceswap.png"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </MaskedImage>
                      <div>
                        <img
                          src="/6-6-img2img.png"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </div>
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <MaskedImage scroll={(scroll - offset[4]) * 1.5}>
                        <img
                          src="/10-0-zfaceswap.png"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </MaskedImage>
                      <div>
                        <img
                          src="/10-1-img2img.png"
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </div>
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div>
        <div>
          <div className="mx-auto max-w-7xl px-6 py-0 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-0">
            <div
              style={{ position: "relative", top: landscape ? -150 : 0 }}
              className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto"
            >
              <AnimationOnScroll
                animateOnce={true}
                animateIn={
                  landscape ? "animate__fadeInLeft" : "animate__fadeInUp"
                }
              >
                <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Una Mirada Mágica al Futuro
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Nuestra tecnología de inteligencia artificial convierte las
                  ecografías en imágenes impresionantes de la vida real,
                  ofreciéndote una ventana única para visualizar cómo podría
                  lucir tu bebé.
                  <br />
                  <br />
                  <p className="font-bold">
                    ¡Acércate a la magia de la vida antes del nacimiento!
                  </p>
                </p>
              </AnimationOnScroll>
            </div>
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-grow">
              <AnimationOnScroll
                animateOnce={true}
                animateIn={
                  landscape ? "animate__fadeInRight" : "animate__fadeInUp"
                }
              >
                <img src="/custom.jpeg" />
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>

      <div className="relative isolate bg-white pb-32 pt-24 sm:pt-32">
        <div
          className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
          aria-hidden="true"
        >
          <div
            className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#FA9391] to-[#FA9391] opacity-30"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
          <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Hacemos realidad tus sueños
            </p>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateOnce={true}
          animateIn="animate__fadeInUp"
          delay={200}
        >
        </AnimationOnScroll>
        <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-1">
          {tiers.map((tier, tierIdx) => (
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <div
                key={tier.id}
                className={classNames(
                  tier.featured
                    ? "relative bg-white shadow-2xl"
                    : "bg-white/60 sm:mx-8 lg:mx-0",
                  tier.featured
                    ? ""
                    : tierIdx === 0
                    ? "rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl"
                    : "sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none",
                  "rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10"
                )}
              >
                <p className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-xl">
                  Cómo funciona
                </p>
                <ul
                  role="list"
                  className="mt-5 space-y-3 text-sm text-gray-600"
                >
                  {tier.features.map((feature, id) => (
                    <li key={feature} className="flex gap-x-3">
                      <strong>{id + 1}.</strong> {feature}
                    </li>
                  ))}
                </ul>
                <br/>
                <p className="mt-4 flex items-baseline gap-x-2">
                  <span className="text-3xl font-bold tracking-tight text-gray-900">
                    {pricing}
                  </span>
                </p>
                <a
                  href="https://wa.link/l1ev3g"
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.featured
                      ? "bg-pink-600 text-white shadow hover:bg-pink-500"
                      : "text-pink-600 ring-1 ring-inset ring-pink-200 hover:ring-pink-300",
                    "mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600 sm:mt-10"
                  )}
                >
                  IR A PAGAR
                </a>
                <a
                  href="https://wa.link/40v8kf"
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.featured
                      ? "bg-pink-600 text-white shadow hover:bg-pink-500"
                      : "text-pink-600 ring-1 ring-inset ring-pink-200 hover:ring-pink-300",
                    "mt-3 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600 sm:mt-3"
                  )}
                >
                  RESOLVER DUDAS POR WHATSAPP ANTES DE PAGAR
                </a>
              </div>
            </AnimationOnScroll>
          ))}
        </div>
      </div>

      <div className="relative isolate bg-white pb-32 pt-4 sm:pt-32">
        <div
          className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
          aria-hidden="true"
        >
          <div
            className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#FA9391] to-[#FA9391] xl:ml-0 xl:mr-[calc(50%-12rem)]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-xl text-center">
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <h2 className="text-lg font-semibold leading-8 tracking-tight text-pink-600">
                Testimonios que nos llenan de satisfacción
              </h2>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateOnce={true}
              animateIn="animate__fadeInUp"
              delay={200}
            >
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Experiencias de Nuestros Clientes
              </p>
            </AnimationOnScroll>
          </div>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
              <figure className="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">
                <blockquote className="p-12 text-xl font-semibold leading-8 tracking-tight text-gray-900">
                  <p>{`“${featuredTestimonial.body}”`}</p>
                </blockquote>
                <figcaption className="flex items-center gap-x-4 border-t border-gray-900/10 px-6 py-4">
                  <img
                    className="h-10 w-10 flex-none rounded-full bg-gray-50"
                    src={featuredTestimonial.author.imageUrl}
                  />
                  <div className="flex-auto">
                    <div className="font-semibold">
                      {featuredTestimonial.author.name}
                    </div>
                  </div>
                </figcaption>
              </figure>
              {testimonials.map((columnGroup, columnGroupIdx) => (
                <div
                  key={columnGroupIdx}
                  className="space-y-8 xl:contents xl:space-y-0"
                >
                  {columnGroup.map((column, columnIdx) => (
                    <div
                      key={columnIdx}
                      className={classNames(
                        (columnGroupIdx === 0 && columnIdx === 0) ||
                          (columnGroupIdx === testimonials.length - 1 &&
                            columnIdx === columnGroup.length - 1)
                          ? "xl:row-span-2"
                          : "xl:row-start-1",
                        "space-y-8"
                      )}
                    >
                      {column.map((testimonial) => (
                        <AnimationOnScroll
                          animateOnce={true}
                          animateIn="animate__fadeInUp"
                          delay={500}
                        >
                          <figure className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
                            <blockquote className="text-gray-900">
                              <p>{`“${testimonial.body}”`}</p>
                            </blockquote>
                            <figcaption className="mt-6 flex items-center gap-x-4">
                              <img
                                className="h-10 w-10 rounded-full bg-gray-50"
                                src={testimonial.author.imageUrl}
                              />
                              <div>
                                <div className="font-semibold">
                                  {testimonial.author.name}
                                </div>
                              </div>
                            </figcaption>
                          </figure>
                        </AnimationOnScroll>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </AnimationOnScroll>
        </div>
      </div>

      <div className="relative isolate bg-white pb-0 pt-0 sm:pt-0">
        <div
          className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
          aria-hidden="true"
        >
          <div
            className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#FA9391] to-[#FA9391] xl:ml-0 xl:mr-[calc(50%-12rem)]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-xl text-center">
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <h2 className="text-lg font-semibold leading-8 tracking-tight text-pink-600">
                En Instagram
              </h2>
              <br/>
            </AnimationOnScroll>
          </div>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                { landscape ? (
                  <div style={{ padding: 20 }}>
                      <InstagramEmbed
                        url="https://www.instagram.com/p/CuSUk0Xri0C/"
                        width={328}
                        height={900}
                      />
                  </div>
                ): null}
                <div style={{ padding: 20 }}>
                  <InstagramEmbed
                    url="https://www.instagram.com/p/Cu_ackjNfZJ/"
                    width={328}
                    height={900}
                  />
                </div>
              </div>
          </AnimationOnScroll>
        </div>
      </div>      

      <div className="relative isolate bg-white pb-0 pt-0 sm:pt-0">
        <div
          className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
          aria-hidden="true"
        >
          <div
            className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#FA9391] to-[#FA9391] xl:ml-0 xl:mr-[calc(50%-12rem)]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-xl text-center">
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Así recibirás tu imagen
              </p>
            </div>
          </AnimationOnScroll>
          <br/>
          </div>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ padding: 20 }} dangerouslySetInnerHTML={{
                  __html: "<video width=\"440px\" loop=\"true\" autoplay=\"autoplay\" muted playsinline><source src=\"video.mp4\" type=\"video/mp4\"/></video>"
                }}>
                </div>
              </div>
          </AnimationOnScroll>
        </div>
      </div> 

      <br />
      <br />
      <br />
      <br />
      <br />


      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0 lg:left-1/2">
          <img
            className="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full"
            src="/7-1-zfaceswap.png"
          />
        </div>
        <AnimationOnScroll
          animateOnce={true}
          animateIn={landscape ? "animate__fadeInLeft" : "animate__fadeInUp"}
        >
          <div className="pb-24 pt-16 sm:pb-32 sm:pt-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:pt-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Oficinas
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Desde el vibrante corazón de Tres Ríos en Costa Rica, hasta la
                  ciudad de la eterna primavera, Medellín en Colombia,
                  transformamos la emoción de la espera en una experiencia
                  visualmente inolvidable.
                </p>
              </div>
              <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                <div>
                  <h3 className="border-l border-pink-600 pl-6 font-semibold text-gray-900">
                    Costa Rica
                  </h3>
                  <address className="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600">
                    <p>Tres Ríos, Cartago</p>
                  </address>
                </div>
                <div>
                  <h3 className="border-l border-pink-600 pl-6 font-semibold text-gray-900">
                    Colombia
                  </h3>
                  <address className="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600">
                    <p>El Poblado, Medellín</p>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </div>

      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        {landscape ? (
          <>
            <br/>
            <br/>
            <br/>
            <br/>
          </>
        ): null}
        <div className="mx-auto max-w-2xl text-center">
          <a
            href="https://wa.link/40v8kf"
            className={"bg-pink-600 text-white rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"}
          >
            RESUELVE TUS DUDAD CON UN ASESOR
          </a>
        </div>
      </div>

      <div className="bg-white py-16 sm:py-24 lg:py-32">
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
          <div className="max-w-xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-7">
            <h2 className="inline sm:block lg:inline xl:block">
              ¡Recibe tips durante tu embarazo!
            </h2>{" "}
            <p className="inline sm:block lg:inline xl:block">
              Suscríbete a nuestro newsletter y <span className="font-semibold text-pink-600 hover:text-pink-500">obtén 50% OFF.
        </span> 
            </p>

          </div>
          <MailchimpSubscribe
            url={
              "https://babyencamino.us12.list-manage.com/subscribe/post?u=d9463bc988445b65c94e02eac&id=9b94c012c8&f_id=001046e0f0"
            }
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </div>
      </div>

      <footer className="bg-white">
        <div className="mx-auto max-w-7xl overflow-hidden py-5 lg:px-8">
          <p className="mt-10 text-center text-sm leading-5 text-gray-500">
            hola@babyencamino.com<br/>
          </p>
          <div className="mt-10 flex justify-center space-x-10">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-10 text-center text-xs leading-5 text-gray-500">
            &copy; 2023 BabyEnCamino, Inc. Todos los derechos reservados.
          </p>
        </div>
      </footer>
    </div>
  );
}